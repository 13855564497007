import React, { useEffect } from 'react';
import {
  Paper,
  Tabs,
  CardContent,
  Card,
  FormControl,
  TextField,
  Button
} from '@mui/material';
import { Typography } from '@material-ui/core';
import { Box } from '@mui/system';
import PropTypes from 'prop-types';
// import { useStyles } from './styles';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tab from '@mui/material/Tab';
import FedExLogo from '../../assets/carrier logos/fedex.png';
import FedExPriLogo from '../../assets/carrier logos/fedex-priority.png';
import ABFLogo from '../../assets/carrier logos/ABFS.png';
import EXLALogo from '../../assets/carrier logos/EXLA.png';
import PITOHIO from '../../assets/carrier logos/PITD.png';
import CTLogo from '../../assets/carrier logos/central-transport.png';
import PYLELogo from '../../assets/carrier logos/PYLE.png';
import YRCLogo from '../../assets/carrier logos/RDWY.png';
import RLLogo from '../../assets/carrier logos/RLcarriers.png';
import WardLogo from '../../assets/carrier logos/WARD.png';
import AACTLogo from '../../assets/carrier logos/AACT.png';
import NEWPENLogo from '../../assets/carrier logos/New Penn.png';
import UPSLogo from '../../assets/carrier logos/Tfroce freight.png';
import ODFLlogo from '../../assets/carrier logos/ODFL.png';
import SEFLlogo from '../../assets/carrier logos/Southeastern freight lines.png';
// import ODFLlogo from '../../assets/carrier logos/ODFL.png';
// import ODFLlogo from '../../assets/carrier logos/ODFL.png';
// import ODFLlogo from '../../assets/carrier logos/ODFL.png';
import ReddawayLogo from '../../assets/carrier logos/Reddaway.png';

import { successToast, ErrorToast } from '../../components/Toasts';
import SAIALogo from '../../assets/carrier logos/SAIA.png';
import CNWYLogo from '../../assets/carrier logos/CNWY.png';
import './GetRates.css';

import { useSelector } from 'react-redux';
import ShipmentTabPanel from './TabPanel';
import { CardTitle } from 'reactstrap';
import {
  EstesQuoteRate,
  FedExQuoteRate,
  AAACooperQuoteRate,
  ABFQuoteRate,
  AduiePyleQuoteRate,
  // GetCarriers,
  CTQuoteRate,
  NewPennQuoteRate,
  ODFLQuoteRate,
  PittOhioQuoteRate,
  ReddawayQuoteRate,
  RLQuoteRate,
  SAIAQuoteRate,
  SeflQuoteRate,
  UPSQuoteRate,
  WardQuoteRate,
  XPOQuoteRate,
  YRCQuoteRate
} from '../../apis/Admin';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { base_url } from '../../apis/constants';
import QuickQuoteTabPanel from './QuickQuoteTabPanel';

function QuickQuoteRates(props) {
  const obj = props.location.state.state;
  const deepCloneObj = props.location.state.deepClone;
  const totalWeightLBS = props.location.state.totalLBSWeight;
  const quote = props.location.state.quote;
  const SelectedRowData = props.location?.state?.DataToShow;
  const [EstesApiLoading, setEstesApiLoading] = useState(false);

  const [GetRatesArray, setGetRatesArray] = useState([]);
  const [GetRateStandarArray, SetGetRateStandarArray] = useState([]);
  const [GetRateGuaranteedArray, SetGetRateGuaranteedArray] = useState([]);
  const [GetRateLowestPriceArray, setGetRateLowestPriceArray] = useState([]);
  const [GetRateQuickDeliveryArray, setGetRateQuickDeliveryArray] = useState(
    []
  );
  const [finalCarrierApis, setFinalCarrierApis] = useState([]);
  const AgencyID = useSelector(state => state.userDetails.agencyLoginID);
  const [data, setData] = useState([]);
  const [errorMessageApi, setErrorMessageApi] = useState([]);

  // const handleCarrierLogo = (carrierName) => {
  //   CarrierLogos.find((items) => {
  //     if(items.name === carrierName) {

  //     }
  //   })
  // }
  const handleRatesError = inValidResults => {
    const temp = [];
    for (let i = 0; i < inValidResults?.length; i++) {
      const Errors = inValidResults[i];
      temp.push({
        errorMessage: Errors?.response?.data,
        carrierName: Errors?.request.responseURL.substring(0, 10)
      });
    }
  };
  const handleAllCarrierRates = validResults => {
    var temp = [];
    if (validResults?.length > 1) {
    }
    for (let i = 0; i < validResults?.length; i++) {
      setEstesApiLoading(false);
      const ShipArray = validResults;
      const originDetail = ShipArray[i]?.originDetail.contactAndAddress;
      const destinationDetail =
        ShipArray[i]?.destinationDetail.contactAndAddress;

      temp.push({
        carrierID: ShipArray[i]?.carrierInfo.id,
        carrierName: ShipArray[i]?.carrierInfo.name,
        serviceType: ShipArray[i]?.serviceType,
        quotationNumber: ShipArray[i]?.quotationNumber,
        deliveryTime: ShipArray[i]?.deliveryTime
          ? ShipArray[i]?.deliveryTime
          : null,
        originPostalCode: originDetail?.address.postalCode
          ? originDetail?.address.postalCode
          : '',
        originCity: originDetail?.address.city
          ? originDetail?.address.city
          : '',
        originStateOrProvinceCode: originDetail?.address.stateOrProvinceCode
          ? originDetail?.address.stateOrProvinceCode
          : '',
        originCountryCode: originDetail?.address.countryCode
          ? originDetail?.address.countryCode
          : '',

        originContactPersonName: originDetail?.contact?.personName,
        originContactCompanyName: originDetail?.contact?.companyName,

        originContactFaxNmb: originDetail?.contact.faxNumber,

        originContactPersonExtension: originDetail?.contact.phoneExtension,

        originContactPersonPhnNmb: originDetail?.contact.phoneNumber,
        originStreetLines: originDetail?.address.streetLines,

        destinationPostalCode: destinationDetail?.address.postalCode
          ? destinationDetail?.address.postalCode
          : '',
        destinationCity: destinationDetail?.address.city
          ? destinationDetail?.address.city
          : '',
        destinationStateOrProvinceCode: destinationDetail?.address
          .stateOrProvinceCode
          ? destinationDetail?.address.stateOrProvinceCode
          : '',
        destinationCountryCode: destinationDetail?.address.countryCode
          ? destinationDetail?.address.countryCode
          : '',
        destinationContactPersonName: destinationDetail?.contact?.personName,
        destinationContactCompanyName: destinationDetail?.contact?.companyName,
        destinationStreetLines: destinationDetail?.address.streetLines,

        destinationContactFaxNmb: destinationDetail?.contact.faxNumber,

        destinationContactPersonExtension:
          destinationDetail?.contact.phoneExtension,

        destinationContactPersonPhnNmb: destinationDetail?.contact.phoneNumber,

        totalSurcharge: ShipArray[i]?.totalSurcharge.amount,

        totalBillingWeight:
          ShipArray[i]?.totalBillingWeight.value == 0
            ? 0
            : ShipArray[i]?.totalBillingWeight.value +
                ' ' +
                ShipArray[i]?.totalBillingWeight.units !==
                null && ShipArray[i]?.totalBillingWeight.units,
        totalBaseCharge: ShipArray[i]?.totalBaseCharge.amount,
        totalCarrierCharge: ShipArray[i]?.totalCarrierCharge.amount,
        totalDistance: ShipArray[i]?.totalDistance.amount,
        totalDutiesAndTaxes: ShipArray[i]?.totalDutiesAndTaxes.amount,
        totalNetFreight: ShipArray[i]?.totalNetFreight.amount,
        totalNetChargeWithDutiesAndTaxes:
          ShipArray[i]?.totalNetChargeWithDutiesAndTaxes.amount,
        totalFreightDiscount: ShipArray[i]?.totalFreightDiscount.amount,
        netDiscount: ShipArray[i]?.netDiscount.amount,
        totalTaxes: ShipArray[i]?.totalTaxes.amount,
        surcharges: ShipArray[i]?.surcharges,
        Rate: ShipArray[i]?.totalNetCharge.amount,
        isGuaranteedDelviery: ShipArray[i]?.isGuaranteedDelviery,
        logo:
          // handleCarrierLogo(ShipArray[i].carrierInfo.name),
          ShipArray[i]?.carrierInfo?.name === 'ESTES'
            ? EXLALogo
            : ShipArray[i]?.carrierInfo?.name.trim() === 'Central Transport'
            ? CTLogo
            : ShipArray[i]?.carrierInfo?.name === 'SAIA'
            ? SAIALogo
            : ShipArray[i]?.carrierInfo?.name === 'FedEx' &&
              ShipArray[i]?.serviceType === 'FEDEX_FREIGHT_ECONOMY'
            ? FedExLogo
            : ShipArray[i]?.carrierInfo?.name === 'FedEx' &&
              ShipArray[i]?.serviceType === 'FEDEX_FREIGHT_PRIORITY'
            ? FedExPriLogo
            : ShipArray[i]?.carrierInfo.name === 'ABF'
            ? ABFLogo
            : ShipArray[i]?.carrierInfo.name.trim() === 'Pitt Ohio'
            ? PITOHIO
            : ShipArray[i]?.carrierInfo.name.trim() === 'Aduie Pyle'
            ? PYLELogo
            : ShipArray[i]?.carrierInfo.name.trim() === 'YRC Freight'
            ? YRCLogo
            : ShipArray[i]?.carrierInfo.name.trim() === 'RL'
            ? RLLogo
            : ShipArray[i]?.carrierInfo.name.toLowerCase().trim() === 'ward'
            ? WardLogo
            : ShipArray[i]?.carrierInfo.name.toLowerCase().trim() ===
              'aaa cooper transportation'
            ? AACTLogo
            : ShipArray[i]?.carrierInfo.name.toLowerCase().trim() ===
              'southeastern freight lines'
            ? SEFLlogo
            : ShipArray[i]?.carrierInfo.name.toLowerCase().trim() ===
              'old dominion freight line'
            ? ODFLlogo
            : ShipArray[i]?.carrierInfo.name.toLowerCase().trim() === 'ups'
            ? UPSLogo
            : ShipArray[i]?.carrierInfo.name.toLowerCase().trim() === 'new penn'
            ? NEWPENLogo
            : ShipArray[i]?.carrierInfo.name.toLowerCase().trim() === 'reddaway'
            ? ReddawayLogo
            : CNWYLogo,
        days: ShipArray[i]?.transitTime ? ShipArray[i]?.transitTime : ''
      });
    }
    setGetRatesArray(temp);

    // setGetRatesArray(temp);
  };
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleSortRatesArray = () => {
    if (value === 1) {
      const StandarArray = GetRatesArray.filter(
        items => items.isGuaranteedDelviery === false
      );
      SetGetRateStandarArray(StandarArray);
    }
    if (value === 2) {
      const GuaranteedArray = GetRatesArray.filter(
        items => items.isGuaranteedDelviery === true
      );
      SetGetRateGuaranteedArray(GuaranteedArray);
    }
    // if (value === 2) {
    //   const LowestPriceArray = GetRatesArray.sort((a, b) =>
    //     a.Rate > b.Rate ? 1 : -1
    //   );
    //   setGetRateLowestPriceArray(LowestPriceArray);
    // }
    if (value === 3) {
      const QuickestDeliveryArray = GetRatesArray.sort((c, d) =>
        c.days > d.days ? 1 : -1
      );
      setGetRateQuickDeliveryArray(QuickestDeliveryArray);
    }
  };

  useEffect(() => {
    handleSortRatesArray();
  }, [GetRatesArray, value]);

  const dispatch = useDispatch();
  // const obj = props.location.state;
  const OriginLocation =
    obj.shipFrom.postalCode +
    ' ' +
    obj.shipFrom.city +
    ' ' +
    obj.shipFrom.stateOrProvinceCode +
    ' ' +
    obj.shipFrom.countryCode;
  const DestinationLocation =
    obj.shipTo.postalCode +
    ' ' +
    obj.shipTo.city +
    ' ' +
    obj.shipTo.stateOrProvinceCode +
    ' ' +
    obj.shipTo.countryCode;

  const OrderWeight = totalWeightLBS;
  const [originLoc, setOriginLoc] = useState(OriginLocation);
  const [destinationLoc, setDestinationLoc] = useState(DestinationLocation);
  const [requoteWeight, setRequoteWeight] = useState(OrderWeight);
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
  };

  const handleChangeIndex = index => {
    setValue(index);
  };
  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`
    };
  }

  const ShipRatesTabs = [
    {
      label: 'All',
      array: GetRatesArray.sort((a, b) => (a.Rate > b.Rate ? 1 : -1))
    },
    { label: 'Standard', array: GetRateStandarArray },
    { label: 'Guaranteed', array: GetRateGuaranteedArray },
    // { label: 'Lowest Price', array: GetRateLowestPriceArray },
    {
      label: 'Quickest Delivery',
      array:
        value === 3
          ? GetRatesArray.sort((c, d) => (c.days > d.days ? 1 : -1))
          : GetRateQuickDeliveryArray
    },
    { label: 'Favorite Carriers', array: GetRatesArray }
  ];

  const getAllData = async (RequoteObj, DeepCloneRequoteObj) => {
    let list = [];
    try {
      const responses = await Promise.allSettled(
        finalCarrierApis.map(endpoint =>
          RequoteObj !== undefined
            ? axios.post(
                endpoint,
                endpoint.includes('YRC') || endpoint.includes('XPO')
                  ? RequoteObj
                  : DeepCloneRequoteObj
              )
            : axios.post(
                endpoint,
                endpoint.includes('YRC') || endpoint.includes('XPO')
                  ? obj
                  : deepCloneObj
              )
        )
      );

      // const results = await Promise.all(
      //   finalCarrierApis
      //     .map(url =>
      //       RequoteObj !== undefined
      //         ? axios.post(
      //             url,
      //             url.includes('YRC') || url.includes('XPO')
      //               ? RequoteObj
      //               : DeepCloneRequoteObj
      //           )
      //         : axios.post(
      //             url,
      //             url.includes('YRC') || url.includes('XPO')
      //               ? obj
      //               : deepCloneObj
      //           )
      //     )
      //     .map(p => p.catch(e => e))
      // );
      // const ValidResults = results.filter(result => !(result instanceof Error));
      // const InValidResults = results.filter(result => result instanceof Error);

      // console.log(
      //   InValidResults,
      //   'InValidResults',
      //   ValidResults,
      //   'ValidResults'
      // );
      return responses.map(response => response);
    } catch (error) {}
  };

  useEffect(() => {
    handleAllCarrierRates(data);
  }, [data]);

  // useEffect(() => {
  const handleSuccessResponse = ApiRes => {
    var temp;
    for (let index = 0; index < ApiRes.data.shipmentDetails.length; index++) {
      let response = ApiRes.data.shipmentDetails[index];
      // temp.unshift(response);
      // temp = data.concat(response);
      setData(prevArray => [...prevArray, response]);
    }
  };
  // let tempErr = [...errorMessageApi];
  const handleRejectRepsone = err => {
    let tempErr = [];
    setEstesApiLoading(false);
    const ErrObj = {
      message: err?.response?.data,
      URL: err?.response?.request?.responseURL
    };
    // tempErr.push({
    //   message: err?.response?.data,
    //   URL: err?.response?.request?.responseURL
    // });
    setErrorMessageApi(prevArray => [...prevArray, ErrObj]);
  };
  const fetchData = async (RequoteObj, DeepCloneRequoteObj) => {
    // let temp = [];
    // let temp2 = [...data];

    // setData(temp2);

    setData([]);
    setGetRatesArray([]);
    setErrorMessageApi([]);
    if (RequoteObj !== undefined) {
      setOriginLoc(
        RequoteObj.shipFrom.city +
          ' ' +
          RequoteObj.shipFrom.stateOrProvinceCode +
          ' ' +
          RequoteObj.shipFrom.postalCode
      );
      setDestinationLoc(
        RequoteObj.shipTo.city +
          ' ' +
          RequoteObj.shipTo.stateOrProvinceCode +
          ' ' +
          RequoteObj.shipTo.postalCode
      );
      setRequoteWeight(RequoteObj?.commodityInfo[0]?.weight?.value);
    }
    setEstesApiLoading(true);
    let temp = RequoteObj !== undefined ? [] : [...data];
    for (let i = 0; i < finalCarrierApis.length; i++) {
      const EndPoint = finalCarrierApis[i];
      RequoteObj !== undefined
        ? axios
            .post(
              EndPoint,
              EndPoint.includes('YRC') || EndPoint.includes('XPO')
                ? RequoteObj
                : DeepCloneRequoteObj
            )
            .then(res => res.status === 200 && handleSuccessResponse(res))
            .catch(err => handleRejectRepsone(err))
        : axios
            .post(
              EndPoint,
              EndPoint.includes('YRC') || EndPoint.includes('XPO')
                ? obj
                : deepCloneObj
            )
            .then(res => res.status === 200 && handleSuccessResponse(res))
            .catch(err => handleRejectRepsone(err));
    }

    // let tempErr = [];
    // const allData = await getAllData(RequoteObj, DeepCloneRequoteObj);
    // for (let i = 0; i < allData.length; i++) {
    //   const element = allData[i];
    //   if (element.status === 'fulfilled') {
    //     setEstesApiLoading(false);
    //     for (
    //       let i = 0;
    //       i < element?.value?.data?.shipmentDetails?.length;
    //       i++
    //     ) {
    //       const innerElement = element.value.data.shipmentDetails[i];
    //       temp.push(innerElement);
    //     }
    //     setData(temp);
    //     handleAllCarrierRates(temp);
    //   }
    //   if (element.status === 'rejected') {
    //     setEstesApiLoading(false);
    //     tempErr.push({
    //       message: element?.reason?.response?.data,
    //       URL: element?.reason?.response?.request?.responseURL
    //     });
    //     // handleAllCarrierRates(temp);
    //   }
    // }
    // setErrorMessageApi(tempErr);
  };
  // useEffect(() => {
  //   handleAllCarrierRates(data);
  // }, [data]);

  const handleGetCarrierNames = data => {
    let tempApis = [];

    for (let i = 0; i < data.length; i++) {
      const CarrierName = data[i].getRateEndPoint.replace('/', '');
      const element = base_url + CarrierName;
      tempApis.push(element);
      // tempApis.push(element.includes('YRC') ? element : null);
    }
    setFinalCarrierApis(tempApis);
    // getPrice();
  };
  // useEffect(() => {
  // }, []);
  const handleApi = EndPoint => {};
  let temp = [];
  let Err = [];
  useEffect(() => {
    setEstesApiLoading(true);
    fetchData();
  }, [finalCarrierApis]);

  useEffect(() => {
    // GetCarriers(AgencyID)
    //   .then(res => res.status === 200 && handleGetCarrierNames(res.data.data))
    //   .catch(err => console.log('Error here...', err));
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        // margin: '10 0 0 0',
        padding: '10 0 10 0',

        backgroundColor: '#EDF2F9'
      }}
    >
      <div
        style={{
          backgroundColor: '#EDF2F9'
        }}
      >
        <Paper style={{ borderRadius: 20 }}>
          <Card sx={{ minWidth: 275 }}>
            <CardTitle>
              <AppBar sx={{ backgroundColor: '#1976d2' }} position='static'>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor='secondary'
                  textColor='inherit'
                  variant='fullWidth'
                  aria-label='full width tabs example'
                >
                  {ShipRatesTabs.map((items, index) => (
                    <Tab
                      style={{ textTransform: 'capitalize' }}
                      label={items.label}
                      {...a11yProps(index)}
                    />
                  ))}
                </Tabs>
              </AppBar>
            </CardTitle>
            <CardContent>
              <div style={{ display: 'flex' }}>
                <div>
                  <Box sx={{ bgcolor: 'background.paper', width: '100%' }}>
                    <SwipeableViews
                      axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                      index={value}
                      onChangeIndex={handleChangeIndex}
                    >
                      {ShipRatesTabs.map((items, index) => (
                        <TabPanel
                          value={value}
                          index={index}
                          dir={theme.direction}
                          // className='scroll'
                          // style={{ height: '78vh' }}
                        >
                          <QuickQuoteTabPanel
                            tabName={items.label}
                            GetRatesArray={items.array}
                            EstesApiLoading={EstesApiLoading}
                            OriginLocation={originLoc}
                            DestinationLocation={destinationLoc}
                            OrderWeight={requoteWeight}
                            value={value}
                            errorMessageApi={errorMessageApi}
                            quote={quote}
                            fetchData={fetchData}
                            obj={obj}
                            SelectedRowData={SelectedRowData}
                          />
                        </TabPanel>
                      ))}
                    </SwipeableViews>
                  </Box>
                </div>
              </div>
            </CardContent>
          </Card>
        </Paper>
      </div>
    </div>
  );
}

export default React.memo(QuickQuoteRates);
