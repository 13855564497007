import MaterialTable from 'material-table';
import { useState, React, useRef, useEffect } from 'react';
import ViewColumnOutlined from '@material-ui/icons/ViewColumn';
import SearchIcon from '@material-ui/icons/SearchSharp';
import ClearSearchIcon from '@material-ui/icons/Clear';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ErrorToast, successToast } from '../../components/Toasts';
import { ToastContainer } from 'react-toastify';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import './ManageUsers.css';
import { useSelector } from 'react-redux';
import { Box, Avatar, AvatarGroup } from '@mui/material';
import { createStyles, makeStyles } from '@material-ui/core';
import { GetAgencies } from '../../apis/Admin';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';

export function ManageUsers({ PreviouProps }) {
  const styles = makeStyles(() =>
    createStyles({
      row: {
        '& button': {
          display: 'none'
        },
        '&:hover': {
          '& button': {
            display: 'block'
          }
        }
        // backgroundColor: 'white'
      }
    })
  );

  const [columnFilters, setColumnFilters] = useState({});
  const [shipmentsLoader, setShipmentsLoader] = useState(false);

  const [agenciesDataArray, setAgenciesDataArray] = useState([]);

  // Load the column filters from storage on component mount
  const hadnleSuccessGetAgencies = ApiRes => {
    setShipmentsLoader(false);
    const ApiResAgencies = ApiRes?.data?.data;
    const tempArr = [];
    for (let index = 0; index < ApiResAgencies.length; index++) {
      const Item = ApiResAgencies[index];
      const Zip = ApiResAgencies[index].zipCode;
      const City = ApiResAgencies[index].city;
      const State = ApiResAgencies[index].state;
      const Country = ApiResAgencies[index].country;
      const AdminFirstName = ApiResAgencies[index].adminInfo.firstName;
      const AdminLastName = ApiResAgencies[index].adminInfo.lastName;

      const updatedArray = {
        ...Item,
        completeAddress: Zip + ' ' + City + ' ' + State + ' ' + Country,
        adminName: AdminFirstName + ' ' + AdminLastName
      };
      tempArr.push(updatedArray);
    }
    const filteredAgencies = tempArr.slice().reverse();
    setAgenciesDataArray(filteredAgencies);
  };

  useEffect(() => {
    setShipmentsLoader(true);
    GetAgencies()
      .then(res => res.status === 200 && hadnleSuccessGetAgencies(res))
      .catch(err => {
        setShipmentsLoader(false);
        ErrorToast(err?.message);
      });
  }, []);

  const AgencyID = useSelector(state => state.userDetails.agencyLoginID);
  const dispatch = useDispatch();

  const [viewShipmentsData, setViewShipmentsData] = useState([]);

  const history = useHistory();

  const tableRef = useRef();

  const [onclickSelectedRow, setOnclickSelectedRow] = useState('');
  const [addiTopBar, setShowAddiTopBar] = useState(false);

  const handleSelectedRow = selectedRow => {
    setOnclickSelectedRow(selectedRow.tableData.id);
    if (selectedRow) {
      setShowAddiTopBar(true);
    } else if (onclickSelectedRow === selectedRow.tableData.id) {
      setOnclickSelectedRow(null);
      setShowAddiTopBar(false);
    }
  };

  !onclickSelectedRow === null &&
    setTimeout(() => {
      setOnclickSelectedRow(null);
    }, 5000);

  const ActiveAgency = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: '#44b700',
      color: '#44b700',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: 'ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""'
      }
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0
      }
    }
  }));

  const InActiveAgency = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: 'rgb(193, 193, 193)',
      color: 'rgb(193, 193, 193)',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`
    }
  }));

  const ConfiguredCarriers = carriers => {
    const ArrayofCarriers = carriers?.carriers;
    const ActiveAgencyCarriers = ArrayofCarriers.filter(items => items.active);
    console.log(ActiveAgencyCarriers.length > 0, 'ActiveAgencyCarriers.length');

    return (
      <div style={{ display: 'flex' }}>
        <AvatarGroup sx={{}} spacing='small' max={5}>
          {ActiveAgencyCarriers.map((items, index) => (
            <Avatar
              sx={{
                bgcolor: 'ButtonFace'
                // objectFit: 'contain'
              }}
              imgProps={{
                style: {
                  height: 55
                }
              }}
              src={`https://tms.dynamicwerx.com/carrier_logos/${items.icon}`}
            />
          ))}
        </AvatarGroup>
      </div>
    );
  };

  const columns = [
    {
      title: 'Photo',
      field: 'company',
      render: list => {
        if (list.logo.includes('Logos')) {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {list.active === true ? (
                <ActiveAgency
                  overlap='circular'
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  variant='dot'
                >
                  <Avatar
                    className='Agency_Profile_Img'
                    src={`data:image/png;base64, ${list.logoBase64}`}
                    alt=''
                  />
                </ActiveAgency>
              ) : (
                <InActiveAgency
                  overlap='circular'
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  variant='dot'
                >
                  <Avatar
                    className='Agency_Profile_Img'
                    src={`data:image/png;base64, ${list.logoBase64}`}
                    alt=''
                  />
                </InActiveAgency>
              )}
              <span style={{ marginLeft: 15 }}>{list.company}</span>
            </div>
          );
        }
      }
    },

    // { title: 'Name', field: 'company' },
    {
      title: 'Full Name',

      field: 'completeAddress'
      // render: rowData =>
      //   `${rowData.zipCode} ${rowData.city} ${rowData.state} ${rowData.country} `
    },
    {
      title: 'Email',
      field: 'adminName'
    },
    {
      title: 'Role',
      field: 'adminLoginId'
    },

    {
      title: 'Status',
      field: 'active',
      render: list => {
        if (list.active === true) {
          return <span style={{ color: 'green' }}>ACTIVE</span>;
        } else {
          return 'IN-ACTIVE';
        }
      }
    },
    {
      title: 'Actions',
      field: '',
      render: list => {
        return (
          <button
            onClick={() => history.push('create-agency', list)}
            type='button'
            class='btn btn-success btn-sm'
          >
            View / Update
          </button>
        );
      }
    }
  ];

  const rando = max => Math.floor(Math.random() * max);
  const words = ['Paper', 'Rock', 'Scissors'];
  const rawData = [];

  for (let i = 0; i < 100; i++)
    rawData.push({ id: rando(300), name: words[i % words.length] });

  const handleRowClicked = (selectedRow, togglePanel) => {
    handleSelectedRow(selectedRow);
  };
  const componentRef = useRef();

  const classes = styles();
  const handleCreateAgency = () => {
    history.push('/create-users');
  };

  return (
    <div ref={componentRef}>
      <ToastContainer
        position='top-center'
        autoClose={5800}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <link
        rel='stylesheet'
        href='https://fonts.googleapis.com/icon?family=Material+Icons'
      />
      {/* <div style={{ display: 'flex', justifyContent: 'center', height: '90%' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <h1>Manage Users</h1>
        </div>
      </div> */}
      <Box sx={{ display: 'flex', flexDirection: 'row-reverse', m: 1 }}>
        <button
          onClick={() => handleCreateAgency()}
          type='button'
          class='btn btn-primary btn-sm'
        >
          Create
        </button>
      </Box>
      <MaterialTable
        style={{ margin: 10 }}
        className={classes.row}
        title='Manage Users'
        id='ifmcontentstoprint'
        tableRef={tableRef}
        icons={{
          Export: () => <ExitToAppIcon style={{ color: '#6BA3E9 ' }} />,
          ViewColumn: () => (
            <ViewColumnOutlined style={{ color: '#6BA3E9', marginRight: 0 }} />
          ),
          Search: () => (
            <SearchIcon style={{ color: '#6BA3E9', marginRight: 0 }} />
          ),
          ResetSearch: () => (
            <ClearSearchIcon style={{ color: '#6BA3E9', marginRight: 0 }} />
          ),
          Filter: () => (
            <FilterAltIcon style={{ color: '#6BA3E9', marginRight: 0 }} />
          ),

          Check: () => (
            <ClearSearchIcon style={{ color: '#6BA3E9', marginRight: 0 }} />
          )
          // PrintIcon: (props, ref) => <MUIPrintIcon {...props} ref={ref} />
        }}
        onRowClick={(evt, selectedRow, togglePanel) =>
          handleRowClicked(selectedRow, togglePanel)
        }
        // localization={{
        //   toolbar: {
        //     showColumnsAriaLabel: 'Show Columns',
        //     exportTitle: 'Export',
        //     exportAriaLabel: 'Export',
        //     exportName: 'Export as CSV',
        //     exportCSVName: 'Export as CSV',
        //     exportPDFName: 'Export as PDF'
        //   },
        //   pagination: {
        //     labelDisplayedRows: '{from}-{to} of {count}'
        //   },
        //   header: {
        //     actions: null
        //   },
        //   body: {
        //     emptyDataSourceMessage: shipmentsLoader ? (
        //       <CircularProgress />
        //     ) : (
        //       'No Records Found'
        //     ),
        //     filterRow: {
        //       filterTooltip: 'Filter'
        //     }
        //   }
        // }}
        options={{
          filterCellStyle: '',
          search: true,
          paging: true,
          maxBodyHeight: '80vh',
          pageSize: 10,
          columnsButton: true,
          filtering: true,
          headerStyle: {
            fontSize: 12,
            fontWeight: 700,
            backgroundColor: '#EDF2F9',
            whiteSpace: 'nowrap'
            // textAlign: 'center'
          },
          rowStyle: rowData => ({
            fontSize: 12,
            textAlign: 'center',
            backgroundColor:
              //   rowData.tableData.id === hoveringOver
              //     ? '#ebf2ff'
              // :
              onclickSelectedRow === rowData.tableData.id ? '#d0e0ff' : '#FFF'
          })
        }}
        // data={agenciesDataArray}
        columns={columns}
      ></MaterialTable>
    </div>
  );
}
